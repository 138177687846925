import { Row } from 'antd';
import { useEffect } from 'react';
import { useDebugValue } from 'react';
import { useState } from 'react';
import { unionBy } from 'lodash'
import styles from './ProductQuantities.module.scss'
import ApiHelper from '../util/ApiHelper';

const cleanQuantities = (...quantities) => {
    // let cleaned = [...quantities].map(q => {
    //     Object.keys(q).forEach(function(key, index) {
    //         q[key] = Number(q[key]);
    //     });
    //     return q
    // })
    return unionBy(...quantities, 'quantity')
}

const getNextQuantity = (qty) => {
    const regx = /^1(0)+$/
    return regx.test(qty.toString()) ? qty * 2.5 : qty * 2
}

const QuantityRow = ({ index, row = '', isMax = false, handleRemoveRow, handleFieldChange, handleMaxQuantityChange }) => {
    return (
        <div className={styles.row}>
            <div><input type="number" name="quantity" defaultValue={row.quantity} onChange={e => handleFieldChange(e, index)} /></div>
            <div><input type="number" name="handling" defaultValue={row.handling} onChange={e => handleFieldChange(e, index)} /></div>
            <div><input type="number" name="shipping" defaultValue={row.shipping} onChange={e => handleFieldChange(e, index)} /></div>
            <div><input type="radio" name="isMax" defaultChecked={isMax} onChange={e => handleMaxQuantityChange(row.quantity)} /></div>
            <div><button className='small' onClick={e => handleRemoveRow(index)}>Del</button></div>
        </div>
    )
}

export const ProductQuantities = (props) => {
    const [qtyRows, setQtyRows] = useState([])
    const [maxPriceQuantity, setMaxPriceQuantity] = useState(props.maxPriceQuantity)

    const handleMaxQuantityChange = (quantity) => {
        setMaxPriceQuantity(quantity)
        props.onMaxPriceQuantityChange(quantity)
    }

    const handleFieldChange = (e, index) => {
        const { name, value } = e.target
        let _rows = qtyRows
        _rows[index][name] = Number(value)
        setQtyRows(_rows)
    }

    const handleRemoveRow = (index) => {
        let _rows = qtyRows
        _rows.splice(index, 1)
        setQtyRows([..._rows])
    }

    const handleAddRow = (event) => {
        let _rows = cleanQuantities(qtyRows, [{
            quantity: getNextQuantity(qtyRows.at(-1).quantity),
            handling: Number(props?.priceDefaults?.handling ?? 0),
            margin: Number(props?.priceDefaults?.margin ?? 0),
            shipping: Number(props?.priceDefaults?.shipping ?? 0),
        }])

        setQtyRows(_rows)
    }

    useEffect(() => {
        let _rows = []
        if (props.quantities) {
            _rows = props.quantities
        }
        else {
            _rows = props.priceBars.map(i => {
                return {
                    quantity: i.quantity,
                    shipping: props.priceSettings.shipping,
                    handling: props.priceSettings.handling,
                }
            })
        }

        _rows = cleanQuantities(_rows, qtyRows)
        setQtyRows(_rows)

    }, [props.priceBars, props.quantities, props.maxPriceQuantity])

    useEffect(() => {
        props.onChange(qtyRows)
    }, [qtyRows])

    return (
        <div className={styles.container}>
            <div className={`${styles.row} ${styles.heading}`}>
                <div>Quantity</div>
                <div>Handling</div>
                <div>Shipping</div>
                <div>Max</div>
            </div>
            {qtyRows.map((row, index) => {
                return <QuantityRow
                    key={`${row.quantity}-${index}`}
                    index={index} row={row}
                    isMax={maxPriceQuantity === row.quantity}
                    {...{ handleFieldChange, handleRemoveRow, handleMaxQuantityChange }}
                />
            })}
            <button onClick={(e) => handleAddRow(e)}>Add row</button>
        </div>
    )
}